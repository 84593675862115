import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { colors } from "../../theme";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { GenericErrorType, LionError } from "./LionError";
import { defaultErrorContents } from "./errorContent";

interface UncriticalErrorDialogProps {
  lionError?: LionError;
  onClose: () => void;
}

export function UncriticalErrorDialog({ lionError, onClose }: UncriticalErrorDialogProps) {
  return !lionError ? null : (
    <Dialog open={!!lionError && lionError.errorType !== GenericErrorType.MAINTENANCE}
            sx={{
                '& .MuiDialog-paper': {
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                }
            }}>
      <DialogTitle>
        <Typography color="error">
          {lionError.content?.title || defaultErrorContents[lionError.errorType].title}
        </Typography>
      </DialogTitle>
      <DialogContent className="flex flex-col items-center">
        <ErrorOutlineIcon fontSize="large" htmlColor={colors.error.main} />
        <Typography>{lionError.content?.message || defaultErrorContents[lionError.errorType].message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
