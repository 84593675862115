import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { useAppContext } from "../../context";
import { GenericErrorType, LionError, LionErrorType } from "../../context/ErrorContext/LionError";
import { useAxios } from "../axiosInstance/useAxiosInstance";
import { useErrorContext } from "../../context/ErrorContext/ErrorContext";
import { useCallback, useMemo } from "react";

export function useApiClient() {
  const { customerScope, isOnMaintenanceRef } = useAppContext();
  const axiosClient = useAxios();
  const { setError } = useErrorContext();

  const handleRequest = useCallback(
    async <T>(errorType: LionErrorType, clbk: (...args: any[]) => Promise<AxiosResponse<T, any>>, ...args: any[]) => {
      if (isOnMaintenanceRef.current) {
        throw new LionError(GenericErrorType.MAINTENANCE);
      }
      if (!customerScope) {
        throw new LionError(GenericErrorType.CRITICAL, { title: "", message: "No CustomerScope found!" });
      }
      try {
        return (await clbk(...args)).data;
      } catch (err) {
        const error = err as AxiosError;
        let newError;
        if (error?.response?.status  === 401) {
            newError = new LionError(GenericErrorType.UNAUTHORIZED);
        } else {
            newError = new LionError(errorType, {
                title: "",
                message: error?.response?.statusText || "",
            });
        }

        if (!ERROR_TYPES_WITHOUT_DIALOG.includes(errorType)) {
          setError(newError);
        }
        throw newError;
      }
    },
    [customerScope, isOnMaintenanceRef, setError]
  );

  return useMemo(
    () => ({
      get: async <T>(url: string, errorType: LionErrorType, config?: AxiosRequestConfig) => {
        return handleRequest<T>(errorType, axiosClient.get, url, config);
      },
      post: async <T, B>(url: string, body: B, errorType: LionErrorType, config?: AxiosRequestConfig) => {
        return handleRequest<T>(errorType, axiosClient.post, url, body, config);
      },
      put: async <T, B>(url: string, body: B, errorType: LionErrorType, config?: AxiosRequestConfig) => {
        return handleRequest<T>(errorType, axiosClient.put, url, body, config);
      },
      remove: async <T>(url: string, errorType: LionErrorType, config?: AxiosRequestConfig) => {
        return handleRequest<T>(errorType, axiosClient.delete, url, config);
      },
    }),
    [axiosClient, handleRequest]
  );
}

const ERROR_TYPES_WITHOUT_DIALOG: LionErrorType[] = [
  GenericErrorType.CRITICAL,
  GenericErrorType.UNSPECIFIED,
];
